import React, { useEffect } from "react"
import { Container } from 'react-bootstrap'
import Navbar from "./Navbar"
import bgImg from "../images/magda.jpg"
import { Row, Col } from 'react-bootstrap'
import SideNav from './SideNav'
import Helmet from "./Helmet"

const Layout = ({ children, sideNavExtra }) => {
  // to assure gray background on page load, change background image not before component mounted
  // otherwise it is white during image load
  useEffect(() => {
    document.documentElement.style.backgroundImage = `url(${bgImg})`;
  }, []);

  return (
    <div className="pb-sm-4 mt-sm-4">
      <Container className="main p-3 p-lg-5 pt-lg-4">
        <Helmet />
        <Navbar />
        <Container className="pt-5">
          <Row>
            <Col lg={3} className="d-none d-lg-block pe-5">
              <SideNav>
                {sideNavExtra}
              </SideNav>
            </Col>
            <Col lg={9} className="ps-lg-5">
              <div className="ps-lg-4">
                {children}
              </div>
            </Col>
          </Row>
        </Container>
        <a style={{ float: "right", opacity: 0.5 }} href={process.env.GATSBY_STRAPI_API_URL + "/admin"}>Admin</a>
        {/* Matomo Image Tracker */}
        <img referrerpolicy="no-referrer-when-downgrade" src="https://coderat.matomo.cloud/matomo.php?idsite=5&amp;rec=1" style={{ border:0 }} alt="" />
        {/* End Matomo */}
      </Container>
    </div>
  )
}

export default Layout
