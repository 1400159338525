import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import ogImage from '../../static/og_image.jpg'

const Metadata = () => (
  <StaticQuery
    query={graphql`
      query helmetQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=0.8, user-scalable=0"
        />
        <title>{data.site.siteMetadata.title}</title>
        <html lang="de" />
        <meta
          itemprop="description"
          content={data.site.siteMetadata.description}
        />


        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:description" content={data.site.siteMetadata.description} />
        <meta property="og:image" content={process.env.GATSBY_BASE_URL + ogImage} />

        <meta property="og:type" content="website" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:secure_url" content={process.env.GATSBY_BASE_URL + ogImage} />

        <meta property="og:image:width" content="256" />
        <meta property="og:image:height" content="192" />

        <meta property="og:url" content={process.env.GATSBY_BASE_URL} />
      </Helmet>
    )}
  />
);

export default Metadata
