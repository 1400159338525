import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"


const SideNav = ({ children }) => {
  const data = useStaticQuery(graphql `
    query PagesSideNavQuery {
      allStrapiWilma19Page(filter: {secondary: {eq: false}}) {
        edges {
          node {
            slug
            title
            description
          }
        }
      }
    }
  `)
  
  return (
    <div className="sidenav">
      <Link to={'/'} activeClassName='active'>
        <h4>{"▸ Startseite"}</h4>
        <p className="mb-4">Übersicht & Neuigkeiten</p>
      </Link>

      {data.allStrapiWilma19Page.edges.map(edge => (
        <Link to={'/' + edge.node.slug} key={edge.node.slug} activeClassName='active'>
          <h4>{"▸ " + edge.node.title}</h4>
          <p className="mb-4">{edge.node.description}</p>
        </Link>
      ))}

      <a href="https://plattenkosmos.berlin">
        <h4>▸ Veranstaltungen</h4>
        <p>Infos über Veranstaltungen in unserem Hof und in der Remise</p>
      </a>

      <div className="pt-5">
        <StaticImage alt="Bild vom Haus" src="../images/house_pink.png" placeholder="tracedSVG" />
      </div>

      <div className="pt-5 sidenav-extra">
        {children}
      </div>
    </div>
  )
}

export default SideNav
