import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { Offcanvas, Nav, Navbar, Container } from 'react-bootstrap';
import Logo from '../images/logo_alt.png'
import SideNav from './SideNav'


const TheNavbar = () => {
  const data = useStaticQuery(graphql `
    query PagesNavQuery {
      allStrapiWilma19Page(filter: {secondary: {eq: true}}) {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `)

  const PageLinks = () => (
    data.allStrapiWilma19Page.edges.map(edge => (
      <Nav className="me-3" key={edge.node.slug}>
        <Link to={'/' + edge.node.slug}>
          {edge.node.title}
        </Link>
      </Nav>
    ))
  )

  return (
    <Navbar expand="lg" className="mb-4">
      <Container fluid>
        <Navbar.Brand>
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </Navbar.Brand>
        <Nav className="d-none d-lg-inline-flex">
          <PageLinks />
        </Nav>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton className="close-button">
          </Offcanvas.Header>
          <Offcanvas.Body className="p-5">
            <SideNav />
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default TheNavbar
